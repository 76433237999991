import PropTypes from "prop-types";

export default function TitleComponent({ children, style }) {
    return (
        <h1
            className="title-style"
            style={{
                fontSize: 28,
                fontWeight: "300",
                textAlign: "center",
                margin: 0,
                ...style,
            }}
        >
            {children}
        </h1>
    );
}

TitleComponent.propTypes = {
    style: PropTypes.object,
    children: PropTypes.string.isRequired,
};
