import { Button, Tabs } from "antd";

import { useState } from "react";

import { useSelector } from "react-redux";

import ActivateDeviceModal from "./ActivateDeviceModal";

import "./_style.scss";
import ActivationEditor from "../../editors/ActivationEditor";
import DirectActivation from "./DirectActivation";
import CouponActivation from "./CouponActivation";

import style from "./style/payment.module.scss";
import TEXT from "../../config/text";
import useTranslation from "../../hooks/useTranslation";
import whatsappImg from "./social.png";

/**
 *  The page is meant for MAC address-based device activation.
 * @returns
 */

export default function ActivationPage() {
  //   const navigate = useNavigate();
  const translation = useTranslation();
  const { widgets, webSettings } = useSelector((state) => state.globalState);

  const { isAdmin } = useSelector((store) => store.globalState);

  const [visibleActivateDeviceModal, setVisibleActivateDeviceModal] =
    useState(false);

  const { background_color, background_image } = webSettings?.pages?.activation;

  const pageStyle = {
    backgroundColor: background_color,
    backgroundImage: `url(${background_image})`,
    backgroundSize: "cover",
  };

  const handleText = (text) => {
    if (translation?.pages?.activation?.[text]) {
      return translation?.pages?.activation?.[text];
    }

    return TEXT?.pages?.activation?.[text];
  };

  const handleWhatsAppRedirect = () => {
    const phoneNumber = "+16102280364";
    const message = "Hi, I'm interested in your packages.";
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <div className="page-wrapper" style={pageStyle}>
      {isAdmin && <ActivationEditor />}
      <div className={style["page-container"]}>
        <Tabs
          centered={true}
          tabBarStyle={{
            color: "white",
            marginTop: 20,
            margin: "auto",
            width: "350px",
          }}
          destroyInactiveTabPane
        >
          <Tabs.TabPane key="1" tab={handleText("direct_pay")}>
            <DirectActivation />
          </Tabs.TabPane>
          {widgets?.coupons?.enabled === "true" && (
            <Tabs.TabPane tab={handleText("gift_code")} key="2">
              <CouponActivation />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>

      <div className="activate-device">
        {/* whatsapp */}
        <div className="container-btn-whatsapp">
          <button
            onClick={handleWhatsAppRedirect}
            className="connect-btn"
            type="button"
          >
            <img
              src={whatsappImg}
              alt="whatsapp"
              style={{
                width: "50px",
                height: "50px",
                objectFit: "cover",
                marginLeft: "0.5rem",
              }}
            />
          </button>
        </div>
        <section className="activation-section">
          <p className={style["text-voucher"]}>
            {handleText("activate_for_qa_description")}
          </p>

          <Button onClick={() => setVisibleActivateDeviceModal(true)}>
            {handleText("activate_qa_button_text")}
          </Button>
        </section>
      </div>

      {visibleActivateDeviceModal && (
        <ActivateDeviceModal
          visible={visibleActivateDeviceModal}
          onClose={() => setVisibleActivateDeviceModal(false)}
        />
      )}
    </div>
  );
}
