import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';

import REQUESTS from '../../../api/requests';

import TEXT from '../../../config/text';
import { ICONS } from '../../../config/icons';

import useTranslation from '../../../hooks/useTranslation';

import EditPlaylist from './EditPlaylist';
import DeletePlaylist from './DeletePlaylist';

import ErrorModal from '../../../components/ErrorModal';
import EnterPinModal from '../../playlists/EnterPinModal';

import styles from "../styles/playlist.module.scss";

export default function Playlist({ playlist, getPlaylists, deviceId }) {

    const { name, url, id } = playlist;

    const translation = useTranslation();

    const [setSearchParams] = useSearchParams();

    const widgets = useSelector(
        (store) => store.globalState.widgets
    )
    const [pin, setPin] = useState(null);
    const [actionType, setActionType] = useState("edit");
    const [errorMessage, setErrorMessage] = useState(null);
    const [openPinModal, setOpenPinModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditPlaylist, setOpenEditPlaylist] = useState(false);
    const [protectedPlaylist, setProtectedPlaylist] = useState(null);


    const confirmProtectedPlaylistPin = (values) => {
        const { pin } = values;

        setPin(pin);

        const query = { id: playlist.id, pin };

        setErrorMessage(null);

        function callback(data) {
            setProtectedPlaylist(data);
            setOpenPinModal(false);

            switch (actionType) {
                case "edit":
                    setOpenEditPlaylist(true);
                    break;
                case "delete":
                    setOpenDeleteModal(true);
                    break;

                default:
                    break;
            }
        }

        function errorCallback(error) {
            setProtectedPlaylist(null);
            setErrorMessage(error.message);
        }

        REQUESTS.PROTECTED_PLAYLIST(query, callback, errorCallback);
    };

    const handleText = (text) => {
        if (translation?.pages?.login_2?.[text]) {
            return translation?.pages?.login_2?.[text];
        }

        return TEXT?.pages?.login_2?.[text];
    };

    useEffect(() => {
        if (errorMessage) {
            setOpenPinModal(false);
        }
    }, [errorMessage])

    return (
        <div>
            <table className={styles['single-playlist']}>
                <tr className={styles['single-playlist_container']}>
                    <td className={styles['playlist']}>
                        <span className={styles['name']}>{name}</span>
                        <Link to={`/manage-playlists/categories?id=${id}`}
                            className={styles['single-playlist_container_url']}
                            onClick={() => { setSearchParams({ id, }) }}>{!playlist["is_protected"]
                                ? url
                                : handleText('protected_playlist')}
                        </Link>
                    </td>
                    {
                        widgets && widgets.xuione && widgets.xuione.enabled === "false" &&
                        <td className={styles['single-playlist_container_controller-btns']}>
                            <button onClick={() => {
                                if (playlist["is_protected"]) {
                                    setOpenPinModal(true);
                                    setActionType("edit");
                                } else {
                                    setOpenEditPlaylist(true)
                                }
                            }}>{ICONS.PLAYLIST_EDIT}</button>

                            <button onClick={() => {
                                if (playlist["is_protected"]) {
                                    setOpenPinModal(true);
                                    setActionType("delete");
                                } else {
                                    setOpenDeleteModal(true);
                                }
                            }}>{ICONS.DELETE}</button>
                        </td>
                    }
                </tr>
            </table>

            {playlist["is_protected"] && openPinModal && (
                <EnterPinModal
                    open={openPinModal}
                    hideModal={() => {
                        setOpenPinModal(false);
                    }}
                    confirmProtectedPlaylistPin={confirmProtectedPlaylistPin}
                />
            )}
            <EditPlaylist
                open={openEditPlaylist}
                onCancel={() => setOpenEditPlaylist(false)}
                getPlaylists={getPlaylists}
                playlist={playlist}
                pin={pin}
                protectedPlaylist={protectedPlaylist}
                setProtectedPlaylist={setProtectedPlaylist}
                deviceId={deviceId}
            />
            <DeletePlaylist
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                getPlaylists={getPlaylists}
                playlist={playlist}
                pin={pin}
            />
            <ErrorModal
                message={errorMessage}
                visible={errorMessage ? true : false}
                onClose={() => setErrorMessage(null)}
            />
        </div>
    )
}
