import { useRef, useState } from "react";

import style from "../_style.module.scss";

export default function ColorPicker({ defaultColor, text, callback }) {
    const primaryRef = useRef();
    const [color, setColor] = useState(defaultColor);

    const debounce = (func, wait, immediate) => {
        let timeout;
        return function () {
            const context = this,
                args = arguments;
            const later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    const changePrimaryColorHandler = debounce((e) => {
        callback(e.target.value);
        setColor(e.target.value);
    }, 350);

    return (
        <div className={style["color-picker"]} onChange={changePrimaryColorHandler}>
            {/* <img src="assets/images/fill_color.png" alt="fill" className={style["fill-color"]} /> */}
            <div className={style["color-block"]} style={{ background: color }}>
                <input type="color" name="primaryColor" ref={primaryRef} />
            </div>

            <span>{text}</span>

            {/* <div onClick={changePrimaryColor}></div> */}

            {/* <div className={style["color-block"]}>
                <div
                    className={style["block"]}
                    style={{ backgroundColor: !color ? defaultColor : color }}
                />
                {(color || defaultColor) && (
                    <div className={style["delete-button"]} onClick={deleteColor}>
                        <DeleteFilled style={{ color: "red" }} />
                    </div>
                )}
            </div> */}
        </div>
    );
}
