import { Switch } from "antd";

export default function SwitchComponent({ title, checked, onChange }) {
    return (
        <div style={{ marginBottom: 10,display: "flex", alignItems: "center", gap: 10  }}>
            {title && <span >{title}</span>}

            <Switch size="small" checked={checked} onChange={onChange} />
        </div>
    );
}
