import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, notification, Tabs } from "antd";

import TEXT from "../../config/text";

import REQUESTS from "../../api/requests";

import Step1 from "./Step1";
import Step2 from "./Step2";

import TabBar from "../../components/TabBar";
import SuccessModal from "../../components/SuccessModal";
import ErrorModal from "../../components/ErrorModal";
import ButtonComponent from "../../components/ButtonComponent";
import { RESELLER_HOST } from "../../config/index";
import useTranslation from "../../hooks/useTranslation";

import RegisterEditor from "../../editors/RegisterEditor";

import "./_style.scss";

/**
 * The page is intended for adding a reseller, it has two pieces.
 *
 * 1. The company name, reseller name, last name, email address, and password are the initial parts.
 * 2. The second step is choosing a country while providing address, phone number, and partner preference (Reseller or Referral, or both), confirming privacy policy and terms of usage.
 *    After reseller registration is shown success or error modal
 *
 * @retun Reseller page
 */

export default function Reseller() {
    const [isAdmin] = useSelector((state) => [state.globalState.isAdmin]);

    const translation = useTranslation();

    const { webSettings } = useSelector((state) => state.globalState);

    const [activeKey, setActiveKey] = useState("1");

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const [agreeWithAll, setAgreeWithAll] = useState(false);

    const [form] = Form.useForm();

    const handleText = (text) => {
        if (translation?.pages?.register?.[text]) {
            return translation?.pages?.register?.[text];
        }

        return TEXT?.pages?.register?.[text];
    };

    const onFinish = (values) => {
        if (!values.is_referral && !values.is_reseller) {
            notification.error({
                description: handleText("select_reseller_message"),
            });

            return;
        }

        setLoading(true);

        function callback(data) {
            setLoading(false);
            setSuccessMessage(handleText("register_success_message"));
        }

        function errorCallback(error) {
            setLoading(false);
            setErrorMessage(error.message);
        }

        REQUESTS.RESELLER(values, callback, errorCallback);
    };

    const closeSuccessModal = () => {
        form.resetFields();
        setSuccessMessage(null);
        setActiveKey("1");
    };

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }, []);

    const pageStyle = {
        backgroundColor: webSettings?.pages.register?.background_color,
        backgroundImage:
            webSettings?.pages?.register?.background_image &&
            `url(${webSettings.pages.register.background_image})`,
        backgroundSize: "cover",
    };

    return (
        <div className="page-wrapper" style={pageStyle}>
            {isAdmin && <RegisterEditor />}

            <Form
                name="reseller"
                form={form}
                onFinish={onFinish}
                autoComplete="off"
                className="reseller-form"
            >
                <Tabs
                    centered={true}
                    activeKey={activeKey}
                    renderTabBar={() => {
                        return (
                            <TabBar tabs={[1, 2]} activeKey={activeKey} onChange={setActiveKey} />
                        );
                    }}
                    style={{ width: 350 }}
                    className="reseller-register-tabs"
                >
                    <Tabs.TabPane tab={"1"} key="1">
                        <Step1 form={form} setStep={() => setActiveKey("2")} />
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={"2"} key="2">
                        <Step2
                            form={form}
                            loading={loading}
                            agreeWithAll={agreeWithAll}
                            setAgreeWithAll={setAgreeWithAll}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </Form>

            <SuccessModal
                message={successMessage}
                visible={successMessage ? true : false}
                onClose={closeSuccessModal}
                customButton={
                    <ButtonComponent
                        type="primary"
                        className="success-modal-ref-btn"
                        title={handleText("reseller")}
                        onClick={() => window.open(RESELLER_HOST)}
                    />
                }
            />

            <ErrorModal
                message={errorMessage}
                visible={errorMessage ? true : false}
                onClose={() => setErrorMessage(null)}
            />
        </div>
    );
}
