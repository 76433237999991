const PATHS = {
    // a
    ACTIVATION: "/activation",
    // b
    // c
    CONTACT_US: "contact-us",
    CHAT:'chat',
    // d
    DEVICES:"/devices",
    // e
    EMAIL_REGISTRATION:'/email-registration',
    // f
    // g
    // h
    HOME: "home",
    // i
    INFO: "/info-link",
    INTALLATION_GUIDE:'/installation-guide',
    // j
    // k
    // l
    LOGIN:"/login",
    // m
    MULTIPLE_DEVICE_LOGIN:"/multiple-device-login",
    MANAGE_PLAYLIST:"/manage-playlists",
    // n
    NEWS: "/news",
    // o
    // p
    PRICING:"/pricing",
    PRIVACY: "/privacy-policy",
    PLAYLISTS:"playlists",
    PASSWORD_SETUP:"password-setup",
    // q
    // r
    RESELLER: "reseller",
    // s
    // t
    TERMS_OF_SALE: "terms-of-sale",
    TERMS_OF_USE: "terms-of-use",
    // u
    UPLOAD_PLAYLIST: "/upload-playlist",
    UNDER_CONSTRUCTION:"/under_construction",
    // v
    VOUCHER_CODE:"voucher-code",
    // w
    // x
    // y
    // z
};

export default PATHS;
