import React from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "../store";

import ColorComponent from "./components/ColorComponent";
import BlockComponent from "./components/BlockComponent";
import ImageComponent from "./components/ImageComponent";

export default function PlatformsEditor() {
  const dispatch = useDispatch();

  const { setWebSettings } = bindActionCreators(actions, dispatch);

  const { webSettings } = useSelector((store) => store.globalState);

  const onChangeBackgroundColor = (color) => {
    let configs = { ...webSettings };

    if (configs.pages.home.platforms) {
      configs.pages.home.platforms.background_color = color;
    } else {
      configs.pages.home.platforms = {
        background_color: color,
      };
    }

    setWebSettings(configs);
  };

  const onChangePlatform = (image, key) => {
    let configs = { ...webSettings };
    if (configs.pages.home.platforms) {
      if (configs.pages.home.platforms[key]) {
        configs.pages.home.platforms[key].icon = image;
      } else {
        configs.pages.home.platforms[key] = { icon: image };
      }
    } else {
      configs.pages.home.platforms = {
        [key]: {
          icon: image,
        },
      };
    }

    setWebSettings(configs);
  };

  return (
    <div>
      <ColorComponent
        text="Background color"
        defaultColor={
          webSettings &&
          webSettings.pages &&
          webSettings.pages.home &&
          webSettings.pages.home.platforms &&
          webSettings.pages.home.platforms.background_color
        }
        callback={onChangeBackgroundColor}
      />
      <BlockComponent title={"Apple TV"}>
        <ImageComponent
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.home &&
            webSettings.pages.home.platforms &&
            webSettings.pages.home.platforms.apple_tv &&
            webSettings.pages.home.platforms.apple_tv.icon
          }
          updateImage={(e) => onChangePlatform(e, "apple_tv")}
          deleteImage={() => onChangePlatform("", "apple_tv")}
        />
      </BlockComponent>
      <BlockComponent title={"LG"}>
        <ImageComponent
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.home &&
            webSettings.pages.home.platforms &&
            webSettings.pages.home.platforms.lg &&
            webSettings.pages.home.platforms.lg.icon
          }
          updateImage={(e) => onChangePlatform(e, "lg")}
          deleteImage={() => onChangePlatform("", "lg")}
        />
      </BlockComponent>
      <BlockComponent title={"Samsung"}>
        <ImageComponent
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.home &&
            webSettings.pages.home.platforms &&
            webSettings.pages.home.platforms.samsung &&
            webSettings.pages.home.platforms.samsung.icon
          }
          updateImage={(e) => onChangePlatform(e, "samsung")}
          deleteImage={() => onChangePlatform("", "samsung")}
        />
      </BlockComponent>

      <BlockComponent title={"Android"}>
        <ImageComponent
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.home &&
            webSettings.pages.home.platforms &&
            webSettings.pages.home.platforms.android &&
            webSettings.pages.home.platforms.android.icon
          }
          updateImage={(e) => onChangePlatform(e, "android")}
          deleteImage={() => onChangePlatform("", "android")}
        />
      </BlockComponent>
      <BlockComponent title={"Philips"}>
        <ImageComponent
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.home &&
            webSettings.pages.home.platforms &&
            webSettings.pages.home.platforms.philips &&
            webSettings.pages.home.platforms.philips.icon
          }
          updateImage={(e) => onChangePlatform(e, "philips")}
          deleteImage={() => onChangePlatform("", "philips")}
        />
      </BlockComponent>
      <BlockComponent title={"Xiaomi"}>
        <ImageComponent
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.home &&
            webSettings.pages.home.platforms &&
            webSettings.pages.home.platforms.xiaomi &&
            webSettings.pages.home.platforms.xiaomi.icon
          }
          updateImage={(e) => onChangePlatform(e, "xiaomi")}
          deleteImage={() => onChangePlatform("", "xiaomi")}
        />
      </BlockComponent>
      <BlockComponent title={"Fire TV"}>
        <ImageComponent
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.home &&
            webSettings.pages.home.platforms &&
            webSettings.pages.home.platforms.fire_tv &&
            webSettings.pages.home.platforms.fire_tv.icon
          }
          updateImage={(e) => onChangePlatform(e, "fire_tv")}
          deleteImage={() => onChangePlatform("", "fire_tv")}
        />
      </BlockComponent>

      <BlockComponent title={"Vidaa"}>
        <ImageComponent
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.home &&
            webSettings.pages.home.platforms &&
            webSettings.pages.home.platforms.vidaa &&
            webSettings.pages.home.platforms.vidaa.icon
          }
          updateImage={(e) => onChangePlatform(e, "vidaa")}
          deleteImage={() => onChangePlatform("", "vidaa")}
        />
      </BlockComponent>
      <BlockComponent title={"Vizio"}>
        <ImageComponent
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.home &&
            webSettings.pages.home.platforms &&
            webSettings.pages.home.platforms.vizio &&
            webSettings.pages.home.platforms.vizio.icon
          }
          updateImage={(e) => onChangePlatform(e, "vizio")}
          deleteImage={() => onChangePlatform("", "vizio")}
        />
      </BlockComponent>
      <BlockComponent title={"Hisense"}>
        <ImageComponent
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.home &&
            webSettings.pages.home.platforms &&
            webSettings.pages.home.platforms.hisense &&
            webSettings.pages.home.platforms.hisense.icon
          }
          updateImage={(e) => onChangePlatform(e, "hisense")}
          deleteImage={() => onChangePlatform("", "hisense")}
        />
      </BlockComponent>
    </div>
  );
}
