import React, { useState, useEffect } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { useSelector } from "react-redux";

import { Form, } from "antd";

import useTranslation from "../../hooks/useTranslation";
import ValidateMacAddress from "../../components/ValidateMacAddress";
import ButtonComponent from "../../components/ButtonComponent";

import REQUESTS from "../../api/requests";

import TEXT from "../../config/text";

import ErrorModal from "../../components/ErrorModal";

import LoginEditor from "../../editors/LoginEditor";

import CodeModal from "./CodeModal";

import styles from "./style/style.module.scss";

export default function LoginByMac() {
  const { webSettings, isAdmin } = useSelector((state) => state.globalState);

  const [value, setValue] = useState("");

  const [searchParams] = useSearchParams();

  const [macIsValid, setMacIsValid] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const [visible, setVisible] = useState(false);

  const [device, setDevice] = useState(null);

  const translation = useTranslation();

  const widgets = useSelector((state) => state.globalState.widgets);

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const handleText = (text) => {
    if (translation?.pages?.login?.[text]) {
      return translation?.pages?.login?.[text];
    }
    return TEXT?.pages?.login?.[text];
  };

  const getOTP = () => {
    const body = { mac: value };

    function callback(data) { }

    REQUESTS.GET_OTP(callback, body)
  };

  const onFinish = (values) => {
    setErrorMessage(null);

    const { code } = values;

    const body = {
      mac: value,
      // code,
    };

    if (device && device["auth_type"] === "device_key") {
      body.key = code.trim();
    } else {
      body.code = code.trim();
    }

    REQUESTS.LOGIN_BY_MAC(
      (data) => {
        localStorage.setItem("TOKEN", data);
        
        if (widgets?.user_login2?.enabled === "true") {
          navigate("/manage-playlists/profile");
        } else {
          navigate("/playlists");
        }
      },
      (err) => {
        setErrorMessage(err.message);
      },
      body
    );
  };

  const pageStyle = {
    backgroundColor: webSettings?.pages?.login?.background_color,
    backgroundImage: `url(${webSettings?.pages?.login?.background_image})`,
    backgroundSize: "cover",
  };

  useEffect(() => {
    if (visible && device && device["auth_type"] === "otp") {
      getOTP()
    }

    if (!visible) {
      form.setFieldValue("code", "");
    }

  }, [visible]);

  useEffect(() => {

    if (searchParams.get("mac")) {
      setValue(searchParams.get("mac"));
    }
  }, []);

  return (
    <div className={styles["page"]} style={pageStyle}>
      {isAdmin && <LoginEditor />}
      <div className={styles["login-by-mac-page"]}>
        <h3 className={styles["login-by-mac-page__title"]}>
          {handleText("title")}
        </h3>

        <Form
          autoComplete="off"
          name="login"
          form={form}
          onFinish={onFinish}
          className={styles["login-by-mac-page__form"]}
        >
          <div style={{ width: "100%" }}>
            <ValidateMacAddress
              setMacIsValid={setMacIsValid}
              value={value}
              setValue={setValue}
              setDevice={setDevice}
            />
            <Form.Item
              shouldUpdate
              className={styles["login-by-mac-page__form__btn"]}
            >
              <ButtonComponent
                title={"LOGIN BY MAC"}
                onClick={() => {
                  if (macIsValid) {
                    setVisible(true);
                  }
                }}
                disabled={!macIsValid}
              />
            </Form.Item>
            <CodeModal
              visible={visible}
              setVisible={setVisible}
              device={device}
              macIsValid={macIsValid}
              onClick={() => form.submit()}
            />
          </div>
        </Form>

        <ErrorModal
          message={errorMessage}
          visible={errorMessage ? true : false}
          onClose={() => { setErrorMessage(null); }}
        />
      </div>
    </div>
  );
}
