import { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import TEXT from "../../config/text";

import useTranslation from "../../hooks/useTranslation";

import UploadByUrl from "./UploadByUrl";
import UploadByXTreme from "./UploadByXTreme";
import UploadByFile from "./UploadByFile";
import TitleComponent from "../../components/TitleComponent";

import UploadPlaylistEditor from "../../editors/UploadPlaylistEditor";

import "./_upload-playlist.scss";
import OtherM3U8 from "./OtherM3U8";
import REQUESTS from "../../api/requests";

/**
 *  The page has three sections and is made to allow users to add playlists in various ways.
 *
 *  1. By url: Entering in the user's device mac address, playlist name, and playlist URL.
 *  2. By file: Entering in the user's device mac address, playlist name and choose the m3u8 file from the PC.
 *  3. By Xtream account: Entering in the user's device mac address, playlist name and playlist url depend xtream account email and password.
 */

export default function UploadPlaylistPage() {
  const [captchaSiteKey, setCaptchaSieKey] = useState(null);
  const [activeKey, setActiveKey] = useState("url");
  const translation = useTranslation();
  const [searchParams] = useSearchParams();
  const { webSettings } = useSelector((store) => store.globalState);

  const isAdmin = useSelector((store) => store.globalState.isAdmin);

  const handleText = (text) => {
    if (translation?.pages?.upload_playlist?.[text]) {
      return translation?.pages?.upload_playlist?.[text];
    }

    return TEXT?.pages?.upload_playlist?.[text];
  };

  const getCaptchaSiteKey = () => {
    REQUESTS.CAPTCHA.GET_SITE_KEY(
      (data) => {
        setCaptchaSieKey(data);
      },
      (err) => { }
    );
  };

  useEffect(() => {
    getCaptchaSiteKey();

    let timeout = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    return clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (searchParams.get("tab")) {
      setActiveKey(searchParams.get("tab"))
    }
  }, [searchParams.get("tab")]);

  const uploadPageStyle = {
    backgroundColor: webSettings?.pages?.upload_playlist?.background_color,
    backgroundImage: `url(${webSettings?.pages?.upload_playlist?.background_image})`,
    backgroundSize: "cover",
  };

  return (
    <div className="page-wrapper" style={uploadPageStyle}>
      {isAdmin && <UploadPlaylistEditor />}
      <div className="upload-playlist-container">
        <TitleComponent>{handleText("title")}</TitleComponent>

        <TitleComponent style={{ fontSize: 18, marginTop: 15 }}>
          {handleText("description")}
        </TitleComponent>
        <div className="upload-playlist-tabs">
          <Tabs
            centered={true}
            tabBarStyle={{ color: "white", marginTop: 20 }}
            destroyInactiveTabPane
            activeKey={activeKey}
            onChange={(key) => {
              setActiveKey(key)
            }}
            className="upload-playlist-tabs"
          >
            <Tabs.TabPane key="url" tab={handleText("url")}>
              <UploadByUrl captchaSiteKey={captchaSiteKey} />
            </Tabs.TabPane>

            <Tabs.TabPane key="file" tab={handleText("file")} >
              <UploadByFile captchaSiteKey={captchaSiteKey} />
            </Tabs.TabPane>

            <Tabs.TabPane key="xtream_account" tab={handleText("xtream_account")}>
              <UploadByXTreme captchaSiteKey={captchaSiteKey} />
            </Tabs.TabPane>
            <Tabs.TabPane key="other" tab={handleText("other")}>
              <OtherM3U8 captchaSiteKey={captchaSiteKey} />
            </Tabs.TabPane>
          </Tabs>
        </div>

      </div>
    </div>
  );
}
