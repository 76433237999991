import { Input } from "antd";

// import mod from "./style/inputComponentStyle.module.scss";

export default function InputComponent(props) {
    switch (props.type) {
        case "password":
            return <Input.Password className="input-component" {...props} />;
        case "file":
            return (
                <div className="m3u8-file-input">
                    {props.name ? (
                        <div style={{
                            color: "white",
                            marginLeft: 10,
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }}>{props.name}</div>
                    ) : (
                        <span style={{ color: "#bfbfbf", marginLeft: 10 }}>M3U8 file</span>
                    )}
                    <Input type="file" {...props} style={{ width: "100%" ,opacity:0,position:"absolute",top:0}} />
                </div>
            );

        default:
            return <Input className="input-component" {...props} />
    }
}
