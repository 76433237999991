import React from 'react';

import { Modal } from 'antd';

import useTranslation from '../../../hooks/useTranslation';

import TEXT from '../../../config/text';
import REQUESTS from '../../../api/requests';

export default function DeleteDevice({ id, open, setOpen,getDevices }) {

    const translation = useTranslation();

    const handleText = (text) => {
        if (translation?.pages?.multiple_device_login?.[text]) {
            return translation?.pages?.multiple_device_login?.[text];
        }
        return TEXT?.pages?.multiple_device_login?.[text];
    };

    const deleteDevice = () => {
        const body = { id };

        function callback(data) {
            setOpen(false);
            getDevices();
        }

        REQUESTS.DELETE_DEVICE(body, callback)
    };
    
    return (
        <Modal
            title={null}
            closable={false}
            open={open}
            onOk={deleteDevice}
            onCancel={() => setOpen(false)}
            okText="Yes"
            cancelText="No"
            className="playlist-modal"
        >
            <span className="delete-text">
                {handleText("delete_playlist_modal_title")}
            </span>
        </Modal>
    )
}
