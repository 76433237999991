import EditorDrawer from "./components/EditorDrawer";

import BlockComponent from "./components/BlockComponent";
import HeaderEditor from "./HeaderEditor";
import SectionThreeEditor from "./SectionThreeEditor";
import SectionTwoEditor from "./SectionTwoEditor";
import SectionFourEditor from "./SectionFourEditor";
import LatestNewEditor from "./LatestNewsEditor";
import PlatformsEditor from "./PlatformsEditor";

export default function HomeEditor() {
    return (
        <div>
            <EditorDrawer title={"Home settings"}>
                <BlockComponent title={"Section one"}>
                    <HeaderEditor />
                </BlockComponent>

                <BlockComponent title={"Platforms"}>
                    <PlatformsEditor/>
                </BlockComponent>

                <BlockComponent title={"Section two"}>
                    <SectionTwoEditor />
                </BlockComponent>

                <BlockComponent title={"Section three"}>
                    <SectionThreeEditor />
                </BlockComponent>

                <BlockComponent title={"Section four"}>
                    <SectionFourEditor />
                </BlockComponent>

                <BlockComponent title={"Latest news"}>
                    <LatestNewEditor />
                </BlockComponent>
            </EditorDrawer>
        </div>
    );
}
