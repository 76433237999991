import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default function PhoneInputPicker({ handleChange, value }) {
    return (
        <>
            <div style={{ width: "100%" }}>
                <PhoneInput
                    international
                    defaultCountry="US"
                    placeholder="Enter phone number"
                    value={value}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    className="phone-input"
                    error={
                        value
                            ? isValidPhoneNumber(value)
                                ? undefined
                                : "Invalid phone number"
                            : "Phone number required"
                    }
                />
            </div>
        </>
    );
}
