import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import IMAGES from "../../config/images";
import REQUESTS from "../../api/requests";
import { API_HOST } from "../../config/index";

import "./style.scss";
import ErrorModal from "../../components/ErrorModal";
import ModalComponent from "../../components/ModalComponent";

export default function UnderConstruction() {
  const [show, setShow] = useState(null);
  const navigate = useNavigate();

  const webSettings = useSelector((state) => state.globalState.webSettings);

  const pageStyle = {
    backgroundColor: webSettings && webSettings.background_color,
    backgroundImage: webSettings && `url(${webSettings.background_image})`,
    backgroundSize: "cover",
  };

  const getAppInfo = () => {
    function callback(data) {
      if (!data.under_construction) {
        navigate("/home");
        window.location.href = "/#/home";
      }
    }

    function errorCallback(err) {
      if (err.message === "Your country is blocked") {
        setShow(true);
      }
    }

    REQUESTS.APP_INFO(callback, errorCallback);
  };

  useEffect(() => {
    getAppInfo();
  }, []);

  return (
    <>
      <ModalComponent visible={show} closable={false}>
        <img src={IMAGES.ERROR} alt="" />
        <span className="blocked-message">Your country is blocked</span>
      </ModalComponent>
      {show && (
        <div className="full-screen-loading" style={{ zIndex: 999 }}></div>
      )}

      <div className="page-wrapper" style={pageStyle}>
        <div className="under-construction-page">
          <img
            src={IMAGES.UNDER_CONSRUCTION_PAGE_TEXT_1}
            alt=""
            className="under-construction-page_text-1"
          />
          <img
            src={IMAGES.UNDER_CONSRUCTION_PAGE_BG}
            alt=""
            className="under-construction-page_img"
          />
          <img
            src={IMAGES.UNDER_CONSRUCTION_PAGE_TEXT_2}
            alt=""
            className="under-construction-page_text-2"
          />
        </div>
      </div>
    </>
  );
}
