import React from "react";

import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "../store";

import EditorDrawer from "./components/EditorDrawer";
import ImageComponent from "./components/ImageComponent";
import ColorComponent from "./components/ColorComponent";
import BlockComponent from "./components/BlockComponent";

export default function InstallationGuideEditor() {
  const dispatch = useDispatch();

  const { setWebSettings } = bindActionCreators(actions, dispatch);

  const webSettings = useSelector((store) => store.globalState.webSettings);

  const onChangeBackgroundImage = (image,key) => {
    let configs = { ...webSettings };
    if (configs.pages.installation_guide) {
      configs.pages.installation_guide[key] = image;
    } else {
      configs.pages.installation_guide = {
        background_color: "",
        [key]: image,
      };
    }
    setWebSettings(configs);
  };

  const onChangeBackgroundColor = (color) => {
    let configs = { ...webSettings };
    if (configs.pages.installation_guide) {
      configs.pages.installation_guide.background_color = color;
    } else {
      configs.pages.installation_guide = {
        background_color: color,
        background_image: "",
      };
    }

    setWebSettings(configs);
  };

  const changeTitleColor = (color) => {
    let configs = { ...webSettings };

    if (configs.pages.installation_guide) {
      configs.pages.installation_guide.title_color = color;
    } else {
      configs.pages.installation_guide = {
        title_color: color,
      };
    }

    setWebSettings(configs);

  };

  const changeTextColor = (color) => {
    let configs = { ...webSettings };

    if (configs.pages.installation_guide) {
      configs.pages.installation_guide.text_color = color;
    } else {
      configs.pages.installation_guide = {
        text_color: color,
      };
    }

    setWebSettings(configs);

  };

  return (
    <EditorDrawer>
      <ImageComponent
        title={"Background image"}
        defaultImage={
          webSettings &&
          webSettings.pages &&
          webSettings.pages.installation_guide &&
          webSettings.pages.installation_guide.background_image
        }
        updateImage={(e) => onChangeBackgroundImage(e,'background_image')}
        deleteImage={() => onChangeBackgroundImage("")}
      />
      <ColorComponent
        text="Background color"
        defaultColor={
          webSettings &&
          webSettings.pages &&
          webSettings.pages.installation_guide &&
          webSettings.pages.installation_guide.background_color
        }
        callback={onChangeBackgroundColor}
      />
      <ColorComponent
        defaultColor={
          webSettings &&
          webSettings.pages &&
          webSettings.pages.installation_guide &&
          webSettings.pages.installation_guide.title_color
        }
        text="Title color"
        callback={changeTitleColor}
      />
      <ColorComponent
        defaultColor={
          webSettings &&
          webSettings.pages &&
          webSettings.pages.installation_guide &&
          webSettings.pages.installation_guide.text_color
        }
        text="Text color"
        callback={changeTextColor}
      />

<BlockComponent
        title={"Step 1"}
      >
        <ImageComponent
          title={""}
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.installation_guide &&
            webSettings.pages.installation_guide.step_1_background_image_1
          }
          updateImage={(e) => onChangeBackgroundImage(e,'step_1_background_image_1')}
          deleteImage={() => onChangeBackgroundImage("")}
        />

        <ImageComponent
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.installation_guide &&
            webSettings.pages.installation_guide.step_1_background_image_2
          }
          updateImage={(e) => onChangeBackgroundImage(e,'step_1_background_image_2')}
          deleteImage={() => onChangeBackgroundImage("")}
        />
      </BlockComponent>

      <BlockComponent
        title={"Step 2"}
      >
        <ImageComponent
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.installation_guide &&
            webSettings.pages.installation_guide.step_2_background_image
          }
          updateImage={(e) => onChangeBackgroundImage(e,'step_2_background_image')}
          deleteImage={() => onChangeBackgroundImage("")}
        />
      </BlockComponent>
      <BlockComponent
        title={"Step 3"}
      >
        <ImageComponent
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.installation_guide &&
            webSettings.pages.installation_guide.step_3_background_image
          }
          updateImage={(e) => onChangeBackgroundImage(e,'step_3_background_image')}
          deleteImage={() => onChangeBackgroundImage("")}
        />
      </BlockComponent>
      <BlockComponent
        title={"Step 4"}
      >
        <ImageComponent
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.installation_guide &&
            webSettings.pages.installation_guide.step_4_background_image
          }
          updateImage={(e) => onChangeBackgroundImage(e,'step_4_background_image')}
          deleteImage={() => onChangeBackgroundImage("")}
        />
      </BlockComponent>
      <BlockComponent
        title={"Step 5"}
      >
        <ImageComponent
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.installation_guide &&
            webSettings.pages.installation_guide.step_5_background_image
          }
          updateImage={(e) => onChangeBackgroundImage(e,'step_5_background_image')}
          deleteImage={() => onChangeBackgroundImage("")}
        />
      </BlockComponent>

      <BlockComponent
        title={"Step 6"}
      >
        <ImageComponent
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.installation_guide &&
            webSettings.pages.installation_guide.step_6_background_image
          }
          updateImage={(e) => onChangeBackgroundImage(e,'step_6_background_image')}
          deleteImage={() => onChangeBackgroundImage("")}
        />
      </BlockComponent>      
      <BlockComponent
        title={"Platforms"}
      >
        <ImageComponent
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.installation_guide &&
            webSettings.pages.installation_guide.platforms_img
          }
          updateImage={(e) => onChangeBackgroundImage(e,'platforms_img')}
          deleteImage={() => onChangeBackgroundImage("")}
        />
      </BlockComponent>
    </EditorDrawer>
  );
}
