import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useSearchParams, useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

import { Form } from 'antd';

import useTranslation from '../../../hooks/useTranslation';
import ButtonComponent from '../../../components/ButtonComponent';
import TEXT from '../../../config/text';
import styles from "../index.module.scss";
import InputComponent from '../../../components/InputComponent';
import REQUESTS from '../../../api/requests';
import Message from '../../../components/Message';
import Wrapper from '../../../components/Wrapper';
import MultipleDeviceLoginEditor from '../../../editors/MultipleDeviceLoginEditor';

export default function PasswordSetup() {
  const [queryValue, setQueryValue] = useState(null);
  const [message, setMessage] = useState({
    type: false,
    text: ""
  });

  let [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const translation = useTranslation();

  const { webSettings, isAdmin } = useSelector((state) => state.globalState);
  const [recaptchaValue, setRecaptchaValue] = useState(false);
  const [captchaSiteKey, setCaptchaSieKey] = useState(null);

  const [form] = Form.useForm();

  const pageStyle = {
    backgroundColor: webSettings?.pages?.multiple_device_login?.background_color,
    backgroundImage: `url(${webSettings?.pages?.multiple_device_login?.background_image})`,
    backgroundSize: "cover",
  };

  const handleText = (text) => {
    if (translation?.pages?.multiple_device_login?.[text]) {
      return translation?.pages?.multiple_device_login?.[text];
    }
    return TEXT?.pages?.multiple_device_login?.[text];
  };

  const getCaptchaSiteKey = () => {
    REQUESTS.CAPTCHA.GET_SITE_KEY(
      (data) => {
        setCaptchaSieKey(data);
      },
      (err) => { }
    );
  };

  const handleCaptchaChange = (value) => {
    REQUESTS.CAPTCHA.SENT_CAPTCHA_TOKEN(
      { token: value },
      (message) => {
        setRecaptchaValue(message.success);
      },
      (err) => setRecaptchaValue(false)
    );
  }

  const onFinish = (values) => {
    const { password } = values;

    const body = {
      email: queryValue.email,
      registration_number: queryValue.code,
      password,
    };

    function callback(res) {
      localStorage.setItem('JWT', res);

      navigate("/devices");
      setQueryValue(null);
    };

    function errorCallback(err) {
      setMessage({
        type: false,
        text: err
      })
    };

    REQUESTS.REGISTER(body, callback, errorCallback)
  }

  const onSubmit = () => {
    form.submit();
  }

  const onValuesChange = () => {
    setMessage({
      type: false,
      text: ''
    });
  };

  useEffect(() => {
    const email = searchParams.get('email');
    const code = searchParams.get('registration_number');

    if (email && code) {
      setQueryValue({
        email,
        code,
      })

      form.setFields([{ name: "email", value: email }]);
    }

    getCaptchaSiteKey();
  }, [])

  return (
    <div style={pageStyle} className='page-wrapper'>
      {isAdmin && <MultipleDeviceLoginEditor />}
      <Wrapper>
        <h3 className={styles["login-multiple-device"]}>
          {handleText("registration")}
        </h3>
        <Form
          autoComplete="off"
          name="login"
          form={form}
          onFinish={onFinish}
          className={styles["form"]}
          onValuesChange={onValuesChange}
        >
          <div className={styles["form-content"]}>
            <Form.Item
              name='email'
              rules={[
                {
                  type: 'email',
                  message: handleText('email_validation_message'),
                },
                {
                  required: true,
                  message: handleText('email_message'),
                },
              ]}
            >
              <InputComponent placeholder={handleText("email_placeholder")} type='email' />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  type: 'password',
                  message: handleText('password_message'),
                },
                {
                  required: true,
                  message: handleText('password_message'),
                },
              ]}
            >
              <InputComponent type="password"
                placeholder={handleText("password_placeholder")} />
            </Form.Item>
          </div>

          {
            captchaSiteKey && <ReCAPTCHA
              sitekey={captchaSiteKey}
              onChange={handleCaptchaChange}
            />
          }

          <div style={{ marginBottom: 24 }}>
            <Message message={message} />
          </div>

          <Form.Item
            shouldUpdate
          >
            <ButtonComponent
              title={"Login"}
              onClick={onSubmit}
              disabled={captchaSiteKey && !recaptchaValue}
            />
          </Form.Item>
        </Form>
      </Wrapper>
    </div>
  )
}
