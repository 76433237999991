import axios from "axios";

import request from "./request";
import URLS from "./url";
import requestQuery from "./requestQuery";

const REQUESTS = {
  // a
  APP_INFO: (callback, errorCallback) => {
    request("get", URLS.APP_INFO, {}, callback, errorCallback, false, false, false);
  },

  APPLICATIONS: (callback, errorCallback) => {
    request("get", URLS.APPLICATIONS, {} , callback, errorCallback);
  },

  ACTIVATE_VOUCHER_CODE: (body, callback, errorCallback) => {
    request("post", URLS.ACTIVATE_VOUCHER_CODE, body, callback, errorCallback);
  },

  ADD_DEVICE: (body, callback, errorCallback) => {
    const JWT = localStorage.getItem('JWT');

    axios.post(URLS.ADD_DEVICE, body, {
      headers: {
        Authorization: `Bearer ${JWT}`
      }
    })
      .then(function (response) {
        callback(response.data)
      })
      .catch(function (error) {
        errorCallback(error.response.data)
      });
  },
  // b
  // c
  CHAT_LOGIN: (callback, errorCallback, body) =>
    request(
      "post",
      `${URLS.CHAT_LOGIN}?device=web_device`,
      body,
      callback,
      errorCallback
    ),

  COUPON_ACTIVATION: (body, callback, errorCallback) => {
    request("post", URLS.COUPON_ACTIVATION, body, callback, errorCallback);
  },

  CAPTCHA: {
    GET_SITE_KEY: (callback, errorCallback) => {
      request("get", URLS.CAPTCHA, {}, callback, errorCallback);
    },
    SENT_CAPTCHA_TOKEN: (body, callback, errorCallback) => {
      request("post", URLS.CAPTCHA, body, callback, errorCallback);
    },
  },

  // d
  DEVICE_INFO: (callback, errorCallback) => {
    request("get", URLS.DEVICE_INFO, {}, callback, errorCallback,
      true,
      true);
  },

  DEVICES: (callback, errorCallback) => {
    const JWT = localStorage.getItem('JWT');

    axios.get(URLS.DEVICES, {
      headers: {
        Authorization: `Bearer ${JWT}`
      }
    })
      .then(function (response) {
        callback(response.data.message)
      })
      .catch(function (error) {
        errorCallback(error)
      });

  },
  DELETE_DEVICE: (body, callback, errorCallback) => {
    const JWT = localStorage.getItem('JWT');

    axios.put(
      URLS.DELETE_DEVICE, body,
      {
        headers: {
          Authorization: `Bearer ${JWT}`
        },
      },
    )
      .then(function (response) {
        callback(response.data.message)
      })
      .catch(function (error) {
        errorCallback(error)
      });

  },

  // e
  EMAIL_REGISTRATION: (body, callback, errorCallback) => {
    request("post", URLS.REGISTRATION_LINK, body, callback, errorCallback);
  },
  // f
  // g
  GET_PACKAGES: (callback, errorCallback) => {
    request("get", URLS.GET_PACKAGES, {}, callback, errorCallback);
  },
  GET_WIDGETS: (callback, errorCallback) =>
    request("get", URLS.GET_WIDGETS, {}, callback, errorCallback),
  GET_STRIPE_SECRET_KEY: (callback, errorCallback, packageId, mac) =>
    request(
      "get",
      `${URLS.STRIPE_SECRET_KEY}?package_id=${packageId}&device_mac=${mac}`,
      {},
      callback,
      errorCallback
    ),
  GET_OTP: (callback, body) => {
    request("post", URLS.GET_OTP, body, callback);
  },
  GET_PLAYLISTS: (callback, errorCallback) => {
    request(
      "get",
      `${URLS.GET_PLAYLISTS}`,
      {},
      callback,
      errorCallback,
      null,
      true
    );
  },
  GET_RESELLER_PACKAGES: (callback, errorCallback) => {
    request("get", URLS.RESELLER_PACKAGES, {}, callback, errorCallback);
  },
  // h
  // i
  IMAGE_UPLOAD: (file, callback, errorCallback) => {
    let formData = new FormData();
    formData.append("value", file);

    request("post", URLS.IMAGE_UPLOAD, formData, callback, errorCallback);
  },
  // j
  // k
  // l
  LANGUAGES: (callback, errorCallback) => {
    request("get", URLS.LANGUAGES, {}, callback, errorCallback);
  },
  LOGIN_BY_MAC: (callback, errorCallback, body) => {
    request("post", URLS.LOGIN_BY_MAC, body, callback, errorCallback);
  },

  LOGIN_MULTIPLE_DEVICES_PAGE: (body, callback, errorCallback) => {
    request("post", URLS.LOGIN_MULTIPLE_DEVICES_PAGE, body, callback, errorCallback);
  },
  LOGIN_DEVCE: (body, callback, errorCallback) => {
    // request("post", URLS.LOGIN_MULTIPLE_DEVICES_PAGE, body, callback, errorCallback);
    const JWT = localStorage.getItem('JWT');

    axios.post(URLS.LOGIN_DEVCE, body, {
      headers: {
        Authorization: `Bearer ${JWT}`
      }
    })
      .then(function (response) {
        callback(response.data.message)
      })
      .catch(function (error) {
        errorCallback(error)
      });

  },
  // m
  MESSAGES: {
    GET: (callback, errorCallback) =>
      request("get", URLS.MESSAGES, {}, callback, errorCallback),
    SEND: (callback, errorCallback, body) =>
      request("post", URLS.MESSAGES, body, callback, errorCallback),
  },
  // n
  // o
  // p
  PAYMENT: (body, callback, errorCallback) => {
    request("post", URLS.PAYMENT, body, callback, errorCallback);
  },
  PAYMENT_INFO: (callback) => {
    request("get", `${URLS.PAYMENT_INFO}`, {}, callback, null, null,
      true);
  },
  PAYMENT_SETTINGS: (callback, errorCallback) => {
    request("get", URLS.PAYMENT_SETTINGS, {}, callback, errorCallback);
  },
  PAY_WITH_CMI: (body, callback, errorCallback) => {
    request("post", URLS.PAY_WITH_CMI, body, callback, errorCallback);
  },

  PLAYLIST_DATA: {
    GET: (query, callback, errorCallback) => {
      request("get", `${URLS.PLAYLIST_DATA}${requestQuery(query)}`, {}, callback, errorCallback,
        null,
        true);
    },
    EDIT: (body, callback, errorCallback) => {
      request("put", `${URLS.PLAYLIST_DATA}`, body, callback, errorCallback,
        null,
        true);
    },
  },

  PLAYLIST: {
    ADD: (body, callback, errorCallback) => {
      request(
        "post",
        `${URLS.PLAYLIST}`,
        body,
        callback,
        errorCallback.ACTIVATE_VOUCHER_CODEnull,
        true
      );
    },
    EDIT: (body, callback, errorCallback) => {
      request(
        "put",
        `${URLS.PLAYLIST}`,
        body,
        callback,
        errorCallback,
        null,
        true
      );
    },

    // DELETE: (body, callback, errorCallback) => {
    //   request(
    //     "delete",
    //     `${URLS.PLAYLIST}`,
    //     body,
    //     callback,
    //     errorCallback,
    //     null,
    //     true
    //   );
    // },
  },
  PLAYLIST_DELETE: (body, callback, errorCallback) => {
    request(
      "delete",
      `${URLS.PLAYLIST_DELETE}`,
      body,
      callback,
      errorCallback,
      null,
      true
    );
  },

  PROTECTED_PLAYLIST: (query, callback, errorCallback) => {
    request(
      "get",
      `${URLS.PROTECTED_PLAYLIST}${requestQuery(query)}`,
      {},
      callback,
      errorCallback,
      null,
      true
    );
  },

  GET_NEWS: (callback, errorCallback) => {
    request(
      "get",
      URLS.GET_NEWS + "?sort=" + JSON.stringify(["ID", "DESC"]),
      {},
      callback,
      errorCallback
    );
  },

  GET_LAST_NEWS: (callback, errorCallback) => {
    request("get", URLS.GET_NEWS + "?limit=3", {}, callback, errorCallback);
  },

  GET_CURRENT_NEWS: (id, callback, errorCallback) => {
    request(
      "get",
      URLS.GET_NEWS + `?filter=${JSON.stringify({ id })}`,
      {},
      callback,
      errorCallback
    );
  },
  GET_RELATED_NEWS: (id, callback, errorCallback) => {
    request(
      "get",
      URLS.GET_NEWS + `?filter_notin=${JSON.stringify({ id: id })}`,
      {},
      callback,
      errorCallback
    );
  },
  SEND_EMAIL: (body, callback, errorCallback) => {
    request("post", URLS.SEND_EMAIL, body, callback, errorCallback);
  },
  PLAYLIST_WITH_MAC: (body, callback, errorCallback) => {
    request("post", URLS.PLAYLIST_WITH_MAC, body, callback, errorCallback);
  },
  PLAYLIST_VALIDATION: (body, callback, errorCallback) => {
    request("post", URLS.PLAYLIST_VALIDATION, body, callback, errorCallback);
  },
  // q
  // r
  RESELLER: (body, callback, errorCallback) => {
    request("post", URLS.RESELLER, body, callback, errorCallback);
  },
  REGISTER: (body, callback, errorCallback) => {
    request("post", URLS.REGISTER, body, callback, errorCallback);
  },
  // s
  // t
  TRANSLATIONS: (id, callback) => {
    request("get", URLS.TRANSLATIONS + `?id=${id}`, {}, callback);
  },
  // u
  UPLOAD_PLAYLIST: (formData, callback, errorCallback) => {
    request("post", URLS.UPLOAD_BY_URL, formData, callback, errorCallback);
  },

  // v

  VALIDATE_MAC: (mac, callback, errorCallback) => {
    request("get", `${URLS.VALIDATE_MAC}${mac}`, {}, callback, errorCallback);
  },

  GET_EDITOR_CONFIGS: (callback, errorCallback) => {
    request("get", URLS.GET_EDITOR_CONFIGS, {}, callback, errorCallback);
  },

  UPDATE_EDITOR_CONFIGS: (body, callback, errorCallback) => {
    request("put", URLS.UPDATE_EDITOR_CONFIGS, body, callback, errorCallback);
  },

  VALIDATE_TOKEN: (body, callback, errorCallback) => {
    request("post", URLS.VALIDATE_TOKEN, body, callback, errorCallback);
  },

  // w
  // x
  // y
  // z
};

export default REQUESTS;
