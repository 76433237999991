import React from "react";

import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "../store";

import EditorDrawer from "./components/EditorDrawer";
import ImageComponent from "./components/ImageComponent";
import ColorComponent from "./components/ColorComponent";

export default function ChatEditor() {
  const dispatch = useDispatch();

  const { setWebSettings } = bindActionCreators(actions, dispatch);

  const webSettings = useSelector((store) => store.globalState.webSettings);

  const onChangeBackgroundImage = (image) => {
    let configs = { ...webSettings };
    if (configs.pages.chat) {
      configs.pages.chat.background_image = image;
    } else {
      configs.pages.chat = {
        background_color: "",
        background_image: image,
        header_background_color: "",
      };
    }
    setWebSettings(configs);
  };

  const onChangeBackgroundColor = (color) => {
    let configs = { ...webSettings };
    if (configs.pages.chat) {
      configs.pages.chat.background_color = color;
    } else {
      configs.pages.chat = {
        background_color: color,
        background_image: "",
        header_background_color: "",
      };
    }

    setWebSettings(configs);
  };

  const onChangeHeaderColor = (color) => {
    let configs = { ...webSettings };

    if (configs.pages.chat) {
      configs.pages.chat.header_background_color = color;
    } else {
      configs.pages.chat = {
        background_color: "",
        background_image: "",
        header_background_color: color,
      };
    }

    setWebSettings(configs);
  };

  return (
    <div>
      <EditorDrawer title={"Chat settings"}>
        <ImageComponent
          title={"Background image"}
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.chat &&
            webSettings.pages.chat.background_image
          }
          updateImage={(e) => onChangeBackgroundImage(e)}
          deleteImage={() => onChangeBackgroundImage("")}
        />
        <ColorComponent
          text="Background color"
          defaultColor={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.chat &&
            webSettings.pages.chat.background_color
          }
          callback={onChangeBackgroundColor}
        />
        <ColorComponent
          text="Chat header color"
          defaultColor={ webSettings &&
            webSettings.pages &&
            webSettings.pages.chat &&
            webSettings.pages.chat.header_background_color}
          callback={onChangeHeaderColor}
        />
      </EditorDrawer>
    </div>
  );
}
