const defaultValues = {
    hidden_channels: [],
    locked_channels: [],
    order_channels: [],
    hidden_channel_categories: [],
    locked_channel_categories: [],
    order_channel_categories: [],
    hidden_movies: [],
    locked_movies: [],
    order_movies: [],
    hidden_movie_categories: [],
    locked_movie_categories: [],
    order_movie_categories: [],
    hidden_series: [],
    locked_series: [],
    order_series: [],
    hidden_series_categories: [],
    locked_series_categories: [],
    order_series_categories: [],
}

export default defaultValues