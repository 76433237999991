import { bindActionCreators } from "redux";

import { useDispatch, useSelector } from "react-redux";

import { actions } from "../store";

import EditorDrawer from "./components/EditorDrawer";
import ColorComponent from "./components/ColorComponent";
import ImageComponent from "./components/ImageComponent";

export default function UploadPlaylistEditor({}) {
    const dispatch = useDispatch();

    const { setWebSettings } = bindActionCreators(actions, dispatch);

    const { webSettings } = useSelector((store) => store.globalState);

    const changeBackgroundColor = (color) => {
        let configs = { ...webSettings };

        configs.pages.upload_playlist.background_color = color;

        setWebSettings(configs);
    };

    const updateImage = (image) => {
        let configs = { ...webSettings };

        configs.pages.upload_playlist.background_image = image;

        setWebSettings(configs);
    };

    return (
        <EditorDrawer title={"Upload playlist settings"}>
            <ImageComponent
                title={"Background image"}
                defaultImage={webSettings?.pages?.upload_playlist?.background_image}
                updateImage={(e) => updateImage(e)}
                deleteImage={() => updateImage("")}
            />

            <ColorComponent
                text="Background color"
                defaultColor={webSettings?.pages?.upload_playlist?.background_color}
                callback={changeBackgroundColor}
            />
        </EditorDrawer>
    );
}
