import React, { useState, useEffect } from "react";
import {
  useSearchParams,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";
import useTranslation from "../../hooks/useTranslation";
import PATHS from "../../config/paths";
import TEXT from "../../config/text";

import classes from "./style/_payment.module.scss";
import REQUESTS from "../../api/requests";

import PaypalComponent from "./components/PaypalComponent";
import StripeComponent from "./components/StripeComponent";

import ValidateMacAddress from "../../components/ValidateMacAddress";
import ButtonComponent from "../../components/ButtonComponent";
import { ICONS } from "../../config/icons";
import SimpleActivationPageEditor from "../../editors/SimpleActivationPageEditor";

export default function SimpleActivationPage() {
  const [mac, setMac] = useState("");

  const [macIsValid, setMacIsValid] = useState(false);

  const [paymentSettings, setPaymentSettings] = useState(null);

  const [currentPackage, setCurrentPackage] = useState([]);

  const [searchParams] = useSearchParams();

  const [stripeSecretKey, setStripeSecretKey] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  const { isAdmin } = useSelector((store) => store.globalState);

  const queryParams = new URLSearchParams(location.search);

  const translation = useTranslation();

  const { webSettings } = useSelector((state) => state.globalState);

  const pageStyle = {
    backgroundColor: webSettings?.pages?.simple_activation?.background_color,
    backgroundImage: `url(${webSettings?.pages?.simple_activation?.background_image})`,
    backgroundSize: "cover",
  };

  const handleText = (text) => {
    if (translation?.pages?.simple_activation?.[text]) {
      return translation?.pages?.simple_activation?.[text];
    }

    return TEXT?.pages?.simple_activation?.[text];
  };

  const returnCustomId = () => {
    if (searchParams.get("mac")) {
      const code = searchParams.get("code");

      const customId = {
        t: "ds", //type
        pi: currentPackage && currentPackage[0] && currentPackage[0].id, //package_id
        dm: searchParams.get("mac"), //device_mac
      };

      if (code) {
        customId.rlc = code; // referral_link_code
      }

      return JSON.stringify(customId);
    } else {
      console.error("Mac address isn't defined");
      return null;
    }
  };

  const updateQueryParams = (key, value) => {
    queryParams.set(key, value);

    const newSearch = `?${queryParams.toString()}`;

    navigate({ search: newSearch });
  };

  const getPackage = () => {
    function callback(data) {
      setCurrentPackage(data.rows);
    }
    function errorCallback() {}

    REQUESTS.GET_PACKAGES(callback, errorCallback);
  };

  const getPaymentSettings = () => {
    REQUESTS.PAYMENT_SETTINGS((data) => {
      setPaymentSettings(data);
    });
  };

  const payByPaypal = () => {
    navigate("/payment-success");
  };

  const getStripeSecretKey = () => {
    REQUESTS.GET_STRIPE_SECRET_KEY(
      (data) => {
        setStripeSecretKey(data);
      },
      (err) => {},
      currentPackage[0].id,
      mac //mac
    );
  };

  useEffect(() => {
    if (searchParams.get("mac")) {
      setMac(searchParams.get("mac"));
    }
  }, [searchParams.get("mac")]);

  useEffect(() => {
    getPackage();
    getPaymentSettings();
  }, []);

  useEffect(() => {
    if (currentPackage && currentPackage[0] && currentPackage[0].id && mac) {
      getStripeSecretKey();
    }
  }, [currentPackage, mac]);

  return (
    <div className={classes["payment-page-main"]} style={pageStyle}>
      {isAdmin && <SimpleActivationPageEditor />}
      {!searchParams.get("mac") && (
        <div className={classes["page-wrapper"]}>
          <div className={classes["mac-adress-input-contaner"]}>
            <p className={classes["mac-adress-input-contaner__text"]}>
              {handleText("mac_adress_modal_title")}
            </p>
            <div className={classes["mac-input"]}>
              <ValidateMacAddress
                value={mac}
                setMacIsValid={setMacIsValid}
                setValue={setMac}
              />
            </div>

            <ButtonComponent
              title="ok"
              disabled={!macIsValid}
              onClick={() => updateQueryParams("mac", mac)}
            />
          </div>
        </div>
      )}
      {searchParams.get("mac") && (
        <div className={classes["page-wrapper"]}>
          <div className={classes["payment-page-container"]}>
            <h1 className={classes["title"]}>{handleText("title")}</h1>
            <p className={classes["warning-text"]}>
              {handleText("warning_text")}
            </p>
            <span className={classes["mac"]}>{searchParams.get("mac")}</span>
            {currentPackage &&
              currentPackage.map((item) => {
                return (
                  <div className={classes["package-style"]} key={item.id}>
                    <div className={classes["checked"]}>{ICONS.CHECKED}</div>
                    <p className={classes["package-text-style"]}>{item.name}</p>
                    <p className={classes["package-schedule"]}>
                      {item.schedule}
                    </p>
                    <div className={classes["package-price"]}>
                      <span className={classes["package-text-style-currency"]}>
                        {paymentSettings && paymentSettings.currency}
                      </span>
                      <span className={classes["package-text-style"]}>
                        {item.price}
                      </span>
                    </div>
                  </div>
                );
              })}

            <p className={classes["privacy-policy-text"]}>
              By clicking on PayPal button you are agreeing to our{" "}
              <Link to={PATHS.PRIVACY} target="_blank">
                {handleText("agree_with_all")}
              </Link>{" "}
            </p>

            {searchParams.get("mac") && (
              <div className={classes["payment"]}>
                {/* {paymentSettings && paymentSettings.paypal_enabled && (
                  <PaypalComponent
                    customId={returnCustomId()}
                    type={"order"}
                    clientId={paymentSettings.paypal_client_id}
                    currency={paymentSettings.currency}
                    selectedPackage={currentPackage && currentPackage[0]}
                    onApprove={payByPaypal}
                  />
                )} */}

                {/* {paymentSettings &&
                  paymentSettings.paypal_enabled &&
                  paymentSettings.stripe_enabled && (
                    <div className={classes["or"]}>{handleText("or")}</div>
                  )} */}

                {paymentSettings &&
                  paymentSettings.stripe_enabled &&
                  paymentSettings.stripe_public_key &&
                  stripeSecretKey && (
                    <StripeComponent
                      paymentSettings={paymentSettings}
                      stripeSecretKey={stripeSecretKey}
                    />
                  )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
