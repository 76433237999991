import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import useTranslation from "../../../hooks/useTranslation";
import TEXT from "../../../config/text";
import REQUESTS from "../../../api/requests";

export default function DeletePlaylist({ 
  open,
  setOpen,
  getPlaylists,
  playlist,
  pin,
}) {

  const translation = useTranslation();

  const handleText = (text) => {
    if (translation?.pages?.login_2?.[text]) {
      return translation?.pages?.login_2?.[text];
    }

    return TEXT?.pages?.login_2?.[text];
  };

const hideModal = () => {
    setOpen(false);
  };

  const deletePlaylist = () => {
    const body = { id: playlist.id };

    if (playlist.is_protected) {
      if (pin) body["pin"] = pin;
    } 

    function callback(data) {
      getPlaylists();
      setOpen(false);
    }
    function errorCallback() {}
    REQUESTS.PLAYLIST_DELETE(body, callback, errorCallback);
  };

  return (
    <Modal
      title={null}
      closable={false}
      open={open}
      onOk={deletePlaylist}
      onCancel={hideModal}
      okText="Yes"
      cancelText="No"
      className="playlist-modal"
    >
      <span className="delete-text">
        {handleText("delete_playlist_modal_title")}
      </span>
    </Modal>
  )
}
