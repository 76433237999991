import React, { useEffect, useState } from "react";

import { Table, Button, Radio } from "antd";
import { LockOutlined, EyeOutlined } from "@ant-design/icons";

import TEXT from "../../../config/text";
import useTranslation from "../../../hooks/useTranslation";

import styles from "../styles/style.module.scss";

export default function PlaylistDataTable({
    data,
    selectedCategory,
    setSelectedCategory,
    type,
    keyValue,
    onLock,
    onHide,
    isLoading,
}) {
    const [dataSource, setDataSource] = useState([]);

    const [filteredCategories, setFilteredCategories] = useState("all");

    const translation = useTranslation();

    const handleText = (text) => {
        if (translation?.pages?.login_2?.[text]) {
            return translation?.pages?.login_2?.[text];
        }

        return TEXT?.pages?.login_2?.[text];
    };

    useEffect(() => {
        let newData = [];

        switch (filteredCategories) {
            case "all":
                newData = data;
                break;

            case "lock":
                newData = data.filter((item) => {
                    return item.locked === true;
                });
                break;

            case "hide":
                newData = data.filter((item) => {
                    return item.hide === true;
                });

                break;
            default:
                break;
        }

        setDataSource(newData);
    }, [data, filteredCategories]);

    return (
        <div>
            <div className={styles["categories-container__radio-btns"]}>
                <Radio.Group
                    onChange={(e) => setFilteredCategories(e.target.value)}
                    value={filteredCategories}
                >
                    <Radio value={"all"} className={styles["radio-btn"]}>
                        {handleText("all")}
                    </Radio>

                    <Radio value={"lock"} className={styles["radio-btn"]}>
                        {handleText("lock")}
                    </Radio>

                    <Radio value={"hide"} className={styles["radio-btn"]}>
                        {handleText("hide")}
                    </Radio>
                </Radio.Group>
            </div>

            <Table
                loading={isLoading}
                rowKey={keyValue}
                scroll={{
                    y: 500,
                }}
                rowClassName={(record, index) => {
                    return record.category_id === selectedCategory?.category_id &&
                        keyValue === "category_id"
                        ? styles["selected-row"]
                        : styles["row"];
                }}
                pagination={false}
                showHeader={false}
                columns={[
                    {
                        title: "Name",
                        dataIndex: type === "category" ? "category_name" : "name",
                        render: (text, record) => {
                            return (
                                <div className={styles["single-categorie"]}>
                                    {type === "category" ? record["category_name"] : record["name"]}
                                    <div className={styles["single-categorie_hide-pin"]}>
                                        <Button
                                            disabled={type === "data" && selectedCategory?.locked}
                                            style={{
                                                cursor:
                                                    type === "data" && selectedCategory?.locked
                                                        ? "no-drop"
                                                        : "pointer",
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onLock(record);
                                            }}
                                        >
                                            <LockOutlined
                                                style={{
                                                    color: record?.locked ? "blue" : "grey",
                                                    fontSize: 16,
                                                }}
                                            />
                                        </Button>

                                        <Button
                                            disabled={type === "data" && selectedCategory?.hide}
                                            style={{
                                                cursor:
                                                    type === "data" && selectedCategory?.hide
                                                        ? "no-drop"
                                                        : "pointer",
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onHide(record);
                                            }}
                                        >
                                            <EyeOutlined
                                                style={{
                                                    color: record?.hide ? "blue" : "grey",
                                                    fontSize: 16,
                                                }}
                                            />
                                        </Button>
                                    </div>
                                </div>
                            );
                        },
                    },
                ]}
                size="small"
                dataSource={dataSource}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            event.stopPropagation();

                            if (setSelectedCategory) {
                                setSelectedCategory(record);
                            }
                        },
                    };
                }}
            />
        </div>
    );
}
