import React, { useEffect, useState } from 'react';
import { Modal, Form, Input } from "antd";

import ValidateMacAddress from '../../../components/ValidateMacAddress';
import ReCAPTCHA from 'react-google-recaptcha';
import useTranslation from '../../../hooks/useTranslation';
import TEXT from '../../../config/text';
import ButtonComponent from '../../../components/ButtonComponent';
import REQUESTS from '../../../api/requests';

import styles from "../styles/devices.module.scss";
import ErrorModal from '../../../components/ErrorModal';

export default function AddDevice({ open, setOpen, getDevices }) {

    const [macValue, setMacValue] = useState("");

    const [macIsValid, setMacIsValid] = useState(false);

    const [device, setDevice] = useState(null);

    const [errorMessage, setErrorMessage] = useState(null);

    const [show, setShow] = useState(false);

    const [recaptchaValue, setRecaptchaValue] = useState(false);
    const [captchaSiteKey, setCaptchaSieKey] = useState(null);

    const translation = useTranslation();

    const [form] = Form.useForm();

    const handleText = (text) => {
        if (translation?.pages?.multiple_device_login?.[text]) {
            return translation?.pages?.multiple_device_login?.[text];
        }
        return TEXT?.pages?.multiple_device_login?.[text];
    };

    const getCaptchaSiteKey = () => {
        REQUESTS.CAPTCHA.GET_SITE_KEY(
            (data) => {
                setCaptchaSieKey(data);
            },
            (err) => { }
        );
    };

    const handleCaptchaChange = (value) => {
        REQUESTS.CAPTCHA.SENT_CAPTCHA_TOKEN(
            { token: value },
            (message) => {
                setRecaptchaValue(message.success);
            },
            (err) => setRecaptchaValue(false)
        );
    }


    const getOTP = () => {
        const body = { mac: macValue };

        function callback(data) {
            setShow(true)
        }

        REQUESTS.GET_OTP(callback, body)
    };

    const onFinish = (values) => {
        const { code } = values;

        const body = {
            mac: macValue,
        }

        if (device && device["auth_type"] === "device_key") {
            body.key = code;
        } else {
            body.code = code;
        }

        function callback(data) {
            setOpen(false);

            setMacIsValid(false);

            setMacValue('');

            getDevices();
            setRecaptchaValue(false)
            form.resetFields();
        }

        function errorCallback(error) {
            setErrorMessage(error.message)
        }

        REQUESTS.ADD_DEVICE(body, callback, errorCallback);
    }

    useEffect(() => {
        if (!open) {
            form.resetFields();
            setShow(false)
        }
        getCaptchaSiteKey();
    }, [open])

    return (<>
        <Modal
            closable={false}
            open={open}
            onCancel={() => { setOpen(false); setMacValue(''); setMacIsValid(false) }}
            footer={null}
        >
            {!show && <div>
                <div className={styles['modal-desc-text']}>
                    <p>{handleText('mac_modal_desc')}</p>
                    <p>{handleText('mac_modal_warning_text')}</p>
                </div>
                <ValidateMacAddress
                    value={macValue}
                    setValue={setMacValue}
                    setDevice={setDevice}
                    setMacIsValid={setMacIsValid}
                />

                {
                    captchaSiteKey && <ReCAPTCHA
                        sitekey={captchaSiteKey}
                        onChange={handleCaptchaChange}
                    />
                }
                <div className={styles['btn-container']}>
                    <ButtonComponent
                        title={handleText("login_by_mac")}
                        disabled={captchaSiteKey ? (!recaptchaValue || !macIsValid) : !macIsValid}
                        onClick={() => {
                            if (macIsValid && device && device["auth_type"] === "otp") {
                                getOTP()
                            } else {
                                setShow(true)
                            }
                        }}
                    />
                </div>

            </div>}

            {
                show && <div>
                    <h1 className={styles["key_or_code-modal-title"]}>
                        {device && device["auth_type"] === "device_key"
                            ? handleText("device_key")
                            : handleText("enter_code")}
                    </h1>
                    <Form
                        autoComplete="off"
                        name="login"
                        form={form}
                        onFinish={onFinish}
                    >
                        <div style={{ width: "100%" }}>
                            <Form.Item
                                name="code"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            device && device["auth_type"] === "device_key"
                                                ? handleText("device_key_input_message")
                                                : handleText("code_input_message"),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={
                                        device && device["auth_type"] === "device_key"
                                            ? handleText("device_key_input_placeholder")
                                            : handleText("code_input_placeholder")
                                    }
                                />
                            </Form.Item>
                        </div>

                        <div className={styles['code-description']}>
                            {device && device["auth_type"] === "device_key"
                                ? handleText("device_key_description")
                                : handleText("code_description")}
                        </div>
                        {
                            captchaSiteKey && <ReCAPTCHA
                                sitekey={captchaSiteKey}
                                onChange={handleCaptchaChange}
                            />
                        }
                        <div className={styles['btn-container']}>
                            <ButtonComponent
                                title={handleText("add_device")}
                                onClick={() => form.submit()}
                                disabled={captchaSiteKey ? (!recaptchaValue || !macIsValid) : !macIsValid}
                            />
                        </div>

                    </Form>
                </div>
            }
        </Modal >
        <ErrorModal
            message={errorMessage}
            visible={errorMessage ? true : false}
            onClose={() => setErrorMessage(null)}
        />
    </>
    )
}
