import React from "react";
import { bindActionCreators } from "redux";

import { useDispatch, useSelector } from "react-redux";

import { actions } from "../store";

import EditorDrawer from "./components/EditorDrawer";
import ImageComponent from "./components/ImageComponent";
import ColorComponent from "./components/ColorComponent";

export default function SimpleActivationPageEditor() {
  const dispatch = useDispatch();

  const { setWebSettings } = bindActionCreators(actions, dispatch);

  const { webSettings } = useSelector((store) => store.globalState);

  const onChangeBackgroundColor = (color) => {

    let configs = { ...webSettings };
    
    if (configs.pages.simple_activation) {
      configs.pages.simple_activation.background_color = color;
    } else {
      configs.pages.simple_activation = {
        background_color: color,
        background_image: "",
      };
    }
    setWebSettings(configs);
  };

  const onChangeBackgroundImage = (image) => {
    let configs = { ...webSettings };

    if (configs.pages.simple_activation) {
        configs.pages.simple_activation.background_image = image;
      } else {
        configs.pages.simple_activation = {
          background_color: '',
          background_image: image,
        };
      }

    setWebSettings(configs);
  };

  return (
    <div>
      <EditorDrawer title="Activation settings">
        <ImageComponent
          title={"Background image"}
          defaultImage={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.simple_activation &&
            webSettings.pages.simple_activation.background_image
          }
          updateImage={(e) => onChangeBackgroundImage(e)}
          deleteImage={() => onChangeBackgroundImage("")}
        />

        <ColorComponent
          text="Background color"
          defaultColor={
            webSettings &&
            webSettings.pages &&
            webSettings.pages.simple_activation &&
            webSettings.pages.simple_activation.background_color
          }
          callback={onChangeBackgroundColor}
        />
      </EditorDrawer>
    </div>
  );
}
