import React, { useEffect } from "react";
import { Modal, Tabs, Form } from "antd";
import useTranslation from "../../hooks/useTranslation";
import TEXT from "../../config/text";
import AddPlaylistByUrl from "./AddPlaylistByUrl";
import AddPlaylistByXtream from "./AddPlaylistByXtream";
import AddPlaylistByFile from "./AddPlaylistByFile";
import AddPlaylistByOtherM3U8 from "./AddPlaylistByOtherM3U8";

export default function AddPlaylist({
  visible,
  onCancel,
  getPlaylists,
  deviceMac,
}) {
  const [form] = Form.useForm();

  const translation = useTranslation();

  const handleText = (text) => {
    if (translation?.pages?.playlist?.[text]) {
      return translation?.pages?.playlist?.[text];
    }

    return TEXT?.pages?.playlist?.[text];
  };

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  return (
    <div>
      <Modal
        closable={false}
        open={visible}
        onCancel={onCancel}
        className="playlist-modal"
        footer={null}
      >
        <div>
            <h1 className="playlist-modal-title">
              {handleText("add_playlist_modal_title")}
            </h1>
            <div className="upload-playlist-tabs">
              <Tabs
              centered={true}
              tabBarStyle={{ color: "white", marginTop: 20 }}
              destroyInactiveTabPane
            >
              <Tabs.TabPane key="1" tab={handleText("url")}>
                <AddPlaylistByUrl
                  deviceMac={deviceMac}
                  getPlaylists={getPlaylists}
                  onCancel={onCancel}
                  form={form}
                />
              </Tabs.TabPane>

              <Tabs.TabPane key="2" tab={handleText("xtream_account")}>
                <AddPlaylistByXtream
                  deviceMac={deviceMac}
                  getPlaylists={getPlaylists}
                  onCancel={onCancel}
                  form={form}
                />
              </Tabs.TabPane>

              <Tabs.TabPane key="3" tab={handleText("other_m3u8")}>
                <AddPlaylistByOtherM3U8
                  deviceMac={deviceMac}
                  getPlaylists={getPlaylists}
                  onCancel={onCancel}
                  form={form}
                />
              </Tabs.TabPane>

              <Tabs.TabPane key="4" tab={handleText("file")}>
                <AddPlaylistByFile
                  deviceMac={deviceMac}
                  getPlaylists={getPlaylists}
                  onCancel={onCancel}
                  form={form}
                />
              </Tabs.TabPane>
            </Tabs>
            </div>
          </div>
      </Modal>
    </div>
  );
}
