import React, { useEffect, useState } from 'react';

import { Modal, Input, Form } from 'antd';

import ReCAPTCHA from 'react-google-recaptcha';

import useTranslation from '../../hooks/useTranslation';

import REQUESTS from '../../api/requests';

import TEXT from '../../config/text';

import ButtonComponent from '../../components/ButtonComponent';

import styles from "./style/style.module.scss";

export default function CodeModal({ visible, setVisible, device, macIsValid, onClick }) {
    const translation = useTranslation();

    const [captchaSiteKey, setCaptchaSieKey] = useState(null);

    const [recaptchaValue, setRecaptchaValue] = useState(false);

    const handleText = (text) => {
        if (translation?.pages?.login?.[text]) {
            return translation?.pages?.login?.[text];
        }
        return TEXT?.pages?.login?.[text];
    };

    const getCaptchaSiteKey = () => {
        REQUESTS.CAPTCHA.GET_SITE_KEY(
            (data) => {
                setCaptchaSieKey(data);
            },
            (err) => { }
        );
    }

    const handleCaptchaChange = (value) => {
        REQUESTS.CAPTCHA.SENT_CAPTCHA_TOKEN(
            { token: value },
            (message) => {
                setRecaptchaValue(message.success);
            },
            (err) => setRecaptchaValue(false)
        );
    }

    useEffect(() => {
        if (!visible) {
            setCaptchaSieKey(null);
        } else {
            getCaptchaSiteKey();
        }
     }, [visible])
    
    return (
        <Modal
            closable={false}
            open={visible}
            onCancel={() => { setVisible(false) }}
            className="key_or_code-modal"
            footer={null}
            bodyStyle={{ width: "100%" }}
            maskStyle={{ backdropFilter: 'blur(50px)' }}
        >
            <h1 className="key_or_code-modal-title">
                {device && device["auth_type"] === "device_key"
                    ? handleText("device_key")
                    : handleText("enter_code")}
            </h1>
            <div style={{ width: "100%" }}>
                <Form.Item
                    name="code"
                    rules={[
                        {
                            required: true,
                            message:
                                device && device["auth_type"] === "device_key"
                                    ? handleText("device_key_input_message")
                                    : handleText("code_input_message"),
                        },
                    ]}
                >
                    <Input
                        placeholder={
                            device && device["auth_type"] === "device_key"
                                ? handleText("device_key_input_placeholder")
                                : handleText("code_input_placeholder")
                        }
                    />
                </Form.Item>
            </div>

            <div className={styles["description"]}>
                {device && device["auth_type"] === "device_key"
                    ? handleText("device_key_description")
                    : handleText("code_description")}
            </div>
            {
                captchaSiteKey && <ReCAPTCHA
                    sitekey={captchaSiteKey}
                    onChange={handleCaptchaChange}
                />
            }
            <div style={{ marginTop: 20 }}>
                <Form.Item
                    shouldUpdate
                    className={styles["login-by-mac-page__form__btn"]}
                >
                    <ButtonComponent
                        title={handleText("button_text")}
                        onClick={onClick}
                        // disabled={captchaSiteKey && !recaptchaValue}
                    />
                </Form.Item>
            </div>
        </Modal>
    )
}
