import { useEffect } from "react";

import useTranslation from "../../hooks/useTranslation";

import TEXT from "../../config/text";
import IMAGES from "../../config/images";

export default function PaymentSuccessPage() {
    const translation = useTranslation();

    const handleText = (text) => {
        if (translation?.pages?.payment_success?.[text]) {
            return translation?.pages?.payment_success?.[text];
        }

        return TEXT?.pages?.payment_success?.[text];
    };

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }, []);
    return (
        <div
            style={{
                width: "100%",
                height: "calc(100vh - 136px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <img src={IMAGES.SUCCESS} />

            <h2 style={{ marginTop: 30, color: "#e6d21b" }}>{handleText("message")}</h2>
        </div>
    );
}
