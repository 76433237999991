import React from "react";

import { useSelector } from "react-redux";

import { Image } from 'antd';

import useTranslation from "../../hooks/useTranslation";

import TEXT from "../../config/text";

import style from "./__style.module.scss";
import InstallationGuideEditor from "../../editors/InstallationGuideEditor";

export default function InstallationGuide() {
  const translation = useTranslation();
  const { webSettings } = useSelector((state) => state.globalState);
  const { isAdmin } = useSelector((store) => store.globalState);

  const handleText = (text) => {
    if (translation?.pages?.installation_guide?.[text]) {
      return translation?.pages?.installation_guide?.[text];
    }
    return TEXT?.pages?.installation_guide?.[text];
  };

  const pageStyle = {
    backgroundColor: webSettings?.pages?.installation_guide?.background_color,
    backgroundImage: `url(${webSettings?.pages?.installation_guide?.background_image})`,
    backgroundSize: "cover",
  };

  return (
    <div className="page-wrapper" style={pageStyle}>
      {isAdmin && <InstallationGuideEditor />}
      <div className={style["page-container"]}>
        <div className={style["installation-guide-page-content"]}>
          <h1
            className={style["installation-guide-page-content_title"]}
            style={{
              color: webSettings?.pages?.installation_guide?.title_color,
            }}
          >
            {handleText("title")}
          </h1>
          <div className={style["installation-guide-page-container"]}>
            <div className={style["installation-guide-page-container_steps"]}>
              <div>
                <div className={style["step-row"]}>
                  <div
                    className={style["step"]}
                  >
                    <span style={{
                      color: webSettings?.pages?.installation_guide?.text_color,
                    }}>
                      {handleText("step")}
                    </span>
                    <span style={{
                      color: webSettings?.pages?.installation_guide?.title_color,
                    }}>
                      1
                    </span>
                  </div>
                  <span
                    className={style["text"]}
                    style={{
                      color: webSettings?.pages?.installation_guide?.text_color,
                    }}
                  >
                    {handleText("step_1_text")}
                  </span>
                </div>
                <div className={style.images}>
                  <Image
                    className={style.img}
                    src={webSettings?.pages?.installation_guide?.step_1_background_image_1}
                    // preview={{width:1000, rootClassName:"preview"}}
                  />
                  <Image
                    className={style.img}
                    src={webSettings?.pages?.installation_guide?.step_1_background_image_2}
                    // preview={{width:1000, rootClassName:"preview"}}
                  />
                </div>
              </div>

              <div>
                <div className={style["step-row"]}>
                  <div
                    className={style["step"]}
                  >
                    <span style={{
                      color: webSettings?.pages?.installation_guide?.text_color,
                    }}>
                      {handleText("step")}
                    </span>
                    <span style={{
                      color: webSettings?.pages?.installation_guide?.title_color,
                    }}>
                      2
                    </span>
                  </div>
                  <span
                    className={style["text"]}
                    style={{
                      color: webSettings?.pages?.installation_guide?.text_color,
                    }}
                  >
                    {handleText("step_2_text")}
                  </span>
                </div>
                <div className={style.images}>
                  <Image
                    className={style.img}
                    src={webSettings?.pages?.installation_guide?.step_2_background_image}
                  />
                </div>
              </div>

              <div>
                <div className={style["step-row"]}>
                  <div
                    className={style["step"]}
                  >
                    <span style={{
                      color: webSettings?.pages?.installation_guide?.text_color,
                    }}>
                      {handleText("step")}
                    </span>
                    <span style={{
                      color: webSettings?.pages?.installation_guide?.title_color,
                    }}>
                      3
                    </span>
                  </div>
                  <span
                    className={style["text"]}
                    style={{
                      color: webSettings?.pages?.installation_guide?.text_color,
                    }}
                  >
                    {handleText("step_3_text")}
                  </span>
                </div>
                <div className={style.images}>
                  <Image
                    className={style.img}
                    src={webSettings?.pages?.installation_guide?.step_3_background_image}
                  />
                </div>
              </div>
              <div>
                <div className={style["step-row"]}>
                  <div
                    className={style["step"]}
                  >
                    <span style={{
                      color: webSettings?.pages?.installation_guide?.text_color,
                    }}>
                      {handleText("step")}
                    </span>
                    <span style={{
                      color: webSettings?.pages?.installation_guide?.title_color,
                    }}>
                      4
                    </span>
                  </div>
                  <span
                    className={style["text"]}
                    style={{
                      color: webSettings?.pages?.installation_guide?.text_color,
                    }}
                  >
                    {handleText("step_4_text")}
                  </span>
                </div>
                <div className={style.images}>
                  <Image
                    className={style.img}
                    src={webSettings?.pages?.installation_guide?.step_4_background_image}
                  />

                </div>
              </div>
              <div>
                <div className={style["step-row"]}>
                  <div
                    className={style["step"]}
                  >
                    <span style={{
                      color: webSettings?.pages?.installation_guide?.text_color,
                    }}>
                      {handleText("step")}
                    </span>
                    <span style={{
                      color: webSettings?.pages?.installation_guide?.title_color,
                    }}>
                      5
                    </span>
                  </div>
                  <span
                    className={style["text"]}
                    style={{
                      color: webSettings?.pages?.installation_guide?.text_color,
                    }}
                  >
                    {handleText("step_5_text")}
                  </span>
                </div>
                <div className={style.images}>
                  <Image
                    className={style.img}
                    src={webSettings?.pages?.installation_guide?.step_5_background_image}
                  />

                </div>
              </div>
              <div>
                <div className={style["step-row"]}>
                  <div
                    className={style["step"]}
                  >
                    <span style={{
                      color: webSettings?.pages?.installation_guide?.text_color,
                    }}>
                      {handleText("step")}
                    </span>
                    <span style={{
                      color: webSettings?.pages?.installation_guide?.title_color,
                    }}>
                      6
                    </span>
                  </div>
                  <span
                    className={style["text"]}
                    style={{
                      color: webSettings?.pages?.installation_guide?.text_color,
                    }}
                  >
                    {handleText("step_6_text")}
                  </span>
                </div>
                <div className={style.images}>
                  <Image
                    className={style.img}
                    src={webSettings?.pages?.installation_guide?.step_6_background_image}
                  />
                </div>
              </div>
            </div>
          </div>
          <Image
            className={style.platforms}
            preview={false}
            src={webSettings?.pages?.installation_guide?.platforms_img}
          />
        </div>
      </div>
    </div>
  );
}
