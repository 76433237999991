import { bindActionCreators } from "redux";

import { useDispatch, useSelector } from "react-redux";

import { actions } from "../store";

import EditorDrawer from "./components/EditorDrawer";
import ColorComponent from "./components/ColorComponent";
import ImageComponent from "./components/ImageComponent";

export default function LoginEditor({}) {
    const dispatch = useDispatch();
    const { setWebSettings } = bindActionCreators(actions, dispatch);
    const { webSettings } = useSelector((store) => store.globalState);

    const onChangeBackgroundColor = (color) => {
        let configs = { ...webSettings };

        if (configs.pages.login) {
            configs.pages.login.background_color = color;
        } else {
            configs.pages.login = {
                background_color: color,
                background_image: "",
            };
        }

        setWebSettings(configs);
    };

    const onChangeBackgroundImage = (image) => {
        let configs = { ...webSettings };

        if (configs.pages.login) {
            configs.pages.login.background_image = image;
        } else {
            configs.pages.login = {
                background_color: "",
                background_image: image,
            };
        }

        setWebSettings(configs);
    };

    return (
        <div>
            <EditorDrawer title={"Login settings"}>
                <ImageComponent
                    title={"Background image"}
                    defaultImage={webSettings?.pages?.login?.background_image}
                    updateImage={(e) => onChangeBackgroundImage(e)}
                    deleteImage={() => onChangeBackgroundImage("")}
                />

                <ColorComponent
                    defaultColor={webSettings?.pages?.login?.background_color}
                    text="Background color"
                    callback={onChangeBackgroundColor}
                />
            </EditorDrawer>
        </div>
    );
}
