import React from "react";
import { useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import StripePaymentForm from "./StripePaymentForm";

export default function StripeComponent({ paymentSettings, stripeSecretKey }) {
  const { webSettings } = useSelector((store) => store.globalState);

  const options = {
    clientSecret: stripeSecretKey,
    appearance: {
      theme: "stripe",
      labels: "floating",
      variables: {
        colorPrimary: "#0570de",
        colorBackground: "#ffffff",
        colorText: "#877e8d",
        // colorText: "#fff",
        colorDanger: "#df1b41",
        fontFamily: "Ideal Sans, system-ui, sans-serif",
        spacingUnit: "2px",
        borderRadius: "4px",
      },
      rules: {
        ".Label": {
          color: "#fff",
        },
        ".Input, .Block": {
          backgroundColor: "transparent",
          paddingRight: "51.2px",
          // border: "transparent",
          border:webSettings?.globals?.inputBorderStyle ==="rectangle"? "1.5px solid #fff":"",
          borderBottom:webSettings?.globals?.inputBorderStyle ==="underline"? "1.5px solid #fff":"",
          borderRadius:0,
          borderColor: "#ffffff",
          outline: "none",
          boxShadow: "none",
          color: "#ffffff",
        },
        ".Input:focus": {
          outline: "none",
          borderColor: "#ffffff",
          boxShadow: "none",
        },
        ".Input::placeholder": {
          color: "#FFFFFF",
        },
      },
    },
    locale: "en"
  };
  const stripePromise = loadStripe(paymentSettings.stripe_public_key);
  return (
    <div>
      <Elements stripe={stripePromise} options={options}>
        <StripePaymentForm />
      </Elements>
    </div>
  );
}
