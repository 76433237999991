import React, { useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { Dropdown } from 'antd';

import { MoreOutlined } from '@ant-design/icons';

import DeleteDevice from './DeleteDevice';

import REQUESTS from '../../../api/requests';

import styles from '../styles/devices.module.scss';

export default function Device({ device, getDevices }) {
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const { widgets } = useSelector((state) => state.globalState);

    const login = () => {
        const body = { id: device.id };

        function callback(data) {
            localStorage.setItem("TOKEN", data);

            if (widgets?.user_login2?.enabled === "true") {
                navigate("/manage-playlists/profile");

            } else if (widgets?.user_login?.enabled === "true") {
                navigate('/playlists')
            }else{
                navigate("/manage-playlists/profile");
            }
        }

        REQUESTS.LOGIN_DEVCE(body, callback)
    }

    const items = [
        {
            key: 'Login',
            label: <div onClick={login}>Login</div>,
        },
        {
            key: 'Activate',
            label: (
                  <Link to={`/activation?mac=${device.mac}`}>Activate</Link>  
            ),
        },
        {
            key: 'Delete',
            label: <span onClick={() => setOpen(true)}>Delete</span>,
        },
    ];

    return (
        <div >
            <div className={styles['device']}>
                <div>
                    <p className={styles['device_info']}><strong>Model </strong> : {device.model}</p>
                    <p className={styles['device_info']}> <strong>Playlist</strong> : {device.playlists?.length}</p>
                    <p className={styles['device_info']}><strong>MAC</strong> : {device.mac}</p>
                </div>

                <Dropdown
                    menu={{
                        items,
                    }}
                    placement="bottom"
                    overlayClassName={styles['menu']}
                >
                    <div className={styles['more-button']}>
                    <MoreOutlined />
                    </div>
                </Dropdown>

            </div>

            <DeleteDevice
                open={open}
                setOpen={setOpen}
                id={device.id}
                getDevices={getDevices}
            />
        </div>
    )
}
