import SwitchComponent from "./SwitchComponent";

export default function BlockComponent({ title, children, onOff, checked, onChange, fastSupport,checkedFastSupport, onChangeFastSupport}) {
    return (
        <div
            style={{
                padding: "10px 0px",
                borderBottom: "1px solid #d9d9d947",
            }}
        >
            <div >
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                    <b style={{ marginBottom: 10 }}>{title}</b>
                    {onOff && <SwitchComponent checked={checked} onChange={onChange} />}
                </div>

                <div>
                    {fastSupport && <SwitchComponent checked={checkedFastSupport} onChange={onChangeFastSupport} title={<b>Fast support</b>} />}
                </div>
            </div>

            <div>{children}</div>
        </div>
    );
}
