import React from "react";
import { Form, Modal } from "antd";

import useTranslation from "../../hooks/useTranslation";
import TEXT from "../../config/text";

import ButtonComponent from "../../components/ButtonComponent";
import InputComponent from "../../components/InputComponent";

import styles from "./style/style.module.scss";

export default function EnterPinModal({
  open,
  hideModal,
  confirmProtectedPlaylistPin,
}) {
  const [form] = Form.useForm();

  const translation = useTranslation();

  const handleText = (text) => {
    if (translation?.pages?.login?.[text]) {
      return translation?.pages?.playlist?.[text];
    }

    return TEXT?.pages?.playlist?.[text];
  };

  return (
    <div>
      <Modal
        title={null}
        footer={null}
        closable={false}
        open={open}
        onCancel={hideModal}
        className="playlist-modal"
      >
        <p className={styles["pin-modal-title"]}>
          {handleText("Enter your PIN")}
        </p>

        <Form onFinish={confirmProtectedPlaylistPin} form={form}>
          <div className={styles["pin-modal-form"]}>
            <Form.Item
              name="pin"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <InputComponent type="password" />
            </Form.Item>
            <div style={{ marginTop: "40px", textAlign: "center" }}>
              <ButtonComponent
                onClick={() => form.submit()}
                title={handleText("Enter")}
              />
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
