import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "../store";

import ImageComponent from "./components/ImageComponent";
import ColorComponent from "./components/ColorComponent";

export default function HeaderEditor() {
    const dispatch = useDispatch();

    const { setWebSettings } = bindActionCreators(actions, dispatch);

    const { webSettings } = useSelector((store) => store.globalState);

    const setBgColor = (color) => {
        let configs = { ...webSettings };

        configs.pages.home.header.background_color = color;

        setWebSettings(configs);
    };

    const setBgImage = (image) => {
        let configs = { ...webSettings };

        configs.pages.home.header.background_image = image;

        setWebSettings(configs);
    };

    const { background_color, background_image } = webSettings?.pages?.home?.header;

    return (
        <>
            <ImageComponent
                title={"Background image"}
                defaultImage={background_image}
                updateImage={(e) => setBgImage(e)}
                deleteImage={() => setBgImage("")}
            />

            <ColorComponent
                text="Background color"
                defaultColor={background_color}
                callback={setBgColor}
            />
        </>
    );
}
